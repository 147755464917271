/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/lazysizes@4.1.4/lazysizes.min.js
 * - /npm/lazysizes@4.1.4/plugins/bgset/ls.bgset.min.js
 * - /npm/lazysizes@4.1.4/plugins/respimg/ls.respimg.min.js
 * - /npm/featherlight@1.7.14/release/featherlight.min.js
 * - /npm/featherlight@1.7.14/release/featherlight.gallery.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/alpinejs@3.10.2/dist/cdn.js
 * - /npm/vanilla-lazyload@17.8.3/dist/lazyload.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
